import React from 'react'
import Layout from '../components/Layout/Layout'

const NotFoundPage = () => {
  return (
    <Layout>
      <div
        style={{ minHeight: '80vh', paddingTop: '3rem' }}
        className="container"
      >
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </Layout>
  )
}
export default NotFoundPage
